import React from "react"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <div>
    <SEO title="Anarchy in the kitchen - 404: Aucunes pages ne correspond à votre recherche" description="Anarchy in the kitchen - Page 404"/>
        <div className="maincontent center">
          <h1 data-text-shadow='Désolée !'>Désolée !</h1>
          <h4>Aucunes pages ne correspond à votre recherche.</h4>
        </div>
  </div>
)

export default NotFoundPage
